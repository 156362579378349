<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <!-- <el-row :gutter="20">
        <el-col :span="12">
          <div class="number">订单编号:{{ info.number }}</div>
        </el-col>
      </el-row> -->

      <el-descriptions :title="`订单编号:${info.order_sn}`" :column="3" border>
        <el-descriptions-item label="订单总价">200</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{
          info.status_text
        }}</el-descriptions-item>
        <el-descriptions-item label="订单类型">{{
          info.order_type_text
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          info.create_time
        }}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{
          info.payment_time
        }}</el-descriptions-item>
        <el-descriptions-item label="订单金额">{{
          info.total_amount
        }}</el-descriptions-item>
        <el-descriptions-item label="支付金额">{{
          info.pay_amount
        }}</el-descriptions-item>

        <el-descriptions-item label="收货人姓名">{{
          info.receiver_name
        }}</el-descriptions-item>
        <el-descriptions-item label="收货人电话">{{
          info.receiver_phone
        }}</el-descriptions-item>
        <el-descriptions-item label="详细地址">{{
          info.receiver_detail_address
        }}</el-descriptions-item>
        <el-descriptions-item label="订单备注">{{
          info.note
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="4" border direction="vertical">
        <el-descriptions-item label="用户名称">{{
          info.user.username
        }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{
          info.user.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="用户头像">
          <el-avatar :size="50" :src="info.user.avatar"></el-avatar>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="3" border>
        <el-descriptions-item label="商品">
          <el-table :data="info.item" stripe style="width: 100%">
            <el-table-column prop="url" label="商品图片">
              <template slot-scope="scope">
                <div class="image">
                  <img
                    :src="scope.row.thumb"
                    alt=""
                    width="100"
                    v-if="scope.row.thumb"
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" width="180">
            </el-table-column>
            <el-table-column prop="address" label="商品价格" width="180">
            </el-table-column>
            <el-table-column prop="description" label="商品描述" width="180">
            </el-table-column>
            <el-table-column prop="quantity" label="商品数量">
            </el-table-column>
            <el-table-column prop="units" label="单位"> </el-table-column>
          </el-table>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getInfo } from "@/api/order.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      info: {},
      tableData: [
        {
          date: "2016-05-02",
          name: "可乐",
          address: "50",
          num: "1",
        },
        {
          date: "2016-05-04",
          name: "雪碧",
          address: "60",
          num: "2",
        },
        {
          date: "2016-05-01",
          name: "蛋糕",
          address: "80",
          num: "3",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    goBack() {
      this.$router.push("/order");
    },
    //目录接口
    async catalogue() {
      console.log(this.$route.query);
      let info = await getInfo(this.$route.query);
      this.info = info.data.data;
      console.log(info.data.data);
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.number {
  font-size: 18px;
  color: #333;
}
.image {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  display: flex;
  justify-items: center;
  align-items: center;
  background: #c0c4cc;
  overflow: hidden;
}
</style>