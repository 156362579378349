import { post } from '@/utils/request'

export function getList(data) {
    return post('admin/order', data)
}

export function getInfo(data) {
    return post('admin/order/detail', data)
}

export function getAdd(data) {
    return post('admin/user/userInfo', data)
}
export function getDel(data) {
    return post('admin/user/userInfo', data)
}
export function getEdit(data) {
    return post('admin/user/userInfo', data)
}
export function getOption(data) {
    return post('admin/user/userInfo', data)
}